@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Noto+Serif+KR&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Noto Serif KR', serif;
  text-align: center;
  background-color: #4FF6B0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

button {
  font-family: 'Noto Serif KR', serif;
  border: none;
  border-radius: 25px;
  background-color: #fff;
  color: #000;
  padding: 10px 20px;
  font-size: 24px;
  cursor: pointer;
}

h1 {
  font-family: "Mulish", sans-serif;
  font-weight: 1000;
  letter-spacing: 2px;
  font-size: 60px;
}

ul {
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

li {
  padding-right: 20px;
  font-size: 24px;
}

li:last-child {
  padding: 0;
}

.container {
  width: 500px;
}

.coin-image {
  width: 400px;
  position: absolute;
  bottom: 50px;
  right: 50px;
  z-index: -1;
}

.intro-text {
  font-size: 28px;
}

.btn-gap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.btn-gap.control > button{
  flex: 1;
}

.cancel {
  color: #6d0000;
  background-color: #e5e5e5;
}

.next {
  background-color: #000;
  color: #fff;
}

.hidden {
  font-size: 16px;
  color: #ffffff;
}


.list-container {
  display: flex;
  flex-direction: column;
  gap: 14px;
}


input[type="number"] {
  background-color: rgb(255, 255, 255);
  border-style: none;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  text-align: center;
  font-size: 20px;
  -moz-appearance: textfield;
  border: 2px solid #aaa;
}

.number-range-input>span {
  margin: 0 5px;
}

.number-range-input input[type="number"]::-webkit-inner-spin-button,
.number-range-input input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="text"].wish {
  border-radius: 18px;
  border: none;
  padding: 10px;
  font-size: 20px
}

input[readonly] {
  border: 1px solid #fff;
  background-color: #00b77a;
  color: #fff;
  text-align: center;
}

.text-medium {
  font-size: 28px;
  font-weight: 600;
}

.text-small {
  font-size: 20px;
}

.user-message {
  display: inline-block;
  padding: 5px 15px;
  border: 1px solid #fff;
  border-radius: 10px;;
  font-size: 16px;
  color: #fff;
  background-color: #00b77a;
}

.contents-gap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input-gap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.btn-mg {
  margin-top: 14px;
}


@media screen and (max-width: 500px) {
  
  button {
    font-size: 16px;
  }
.container {
  width: 314px;
  padding: 0 20px;
  margin: 0 auto;
}

  h1 {
    font-size: 28px;
  }

  li {
    font-size: 20px;
  }

  .intro-text {
    font-size: 16px;
  }

  .start-btn {
    font-size: 16px;
  }

  .coin-image {
    width: 340px;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    opacity: .4;
  }

  .text-medium {
    font-size: 17px;
  }

  .text-small {
    font-size: 14px;
  }

  input[type="number"] {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }

  .user-message {
    font-size: 14px;
  }

  input[type="text"].wish {
    font-size: 16px;
  }
}